





















import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import imageCompression from 'browser-image-compression'
import compressFile from '@/shared/helpers/compress-file.helper'

@Component
export default class MultipleFileField extends AbstractField {
  url: string|null = null

  changeFile(value: any) {
    let files: any = []

    value.forEach(async (file: any) => {
      const response = await compressFile(file)
      return files.push(response)
    })

    this.value = files
    this.removeError()
  }
}
